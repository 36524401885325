import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
import ForgotPassword from '../pages/ForgotPassword';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import ProductsPage from '../pages/client/ProductsPage';
import ResetPassword from '../pages/ResetPassword';
import SignUpPage from '../pages/SignUpPage';
import UserPage from '../pages/client/UserPage';
import VerifyEmail from '../pages/VerifyEmail';
import { Route, Routes } from 'react-router-dom';
import AdminDashboardLayout from 'src/layouts/dashboard/AdminDashboardLayout';
import BusinessRegistrationPage from 'src/pages/client/BusinessRegistrationPage';
import ClientDashboardPage from 'src/pages/client/ClientDashboardPage';
import CompanyRegistrationPage from 'src/pages/client/CompanyRegistrationPage';
import DashboardAppPage from 'src/pages/client/DashboardAppPage';
import HomePage from 'src/pages/client/HomePage';
import NewBusinessRegistrationPage from 'src/pages/client/NewBusinessRegistrationPage';
import NewNameReservationPage from 'src/pages/client/NewNameReservationPage';
import PaymentConfirmationPage from 'src/pages/client/PaymentConfirmationPage';
import ProfilePage from 'src/pages/client/ProfilePage';
import AdminAnnualReturn from 'src/pages/admin/AdminAnnualReturn';
import AdminBankAccount from 'src/pages/admin/AdminBankAccount';
import AdminBusinessNameRegistrations from 'src/pages/admin/AdminBusinessNameRegistrations';
import AdminCertifiedTrueCopy from 'src/pages/admin/AdminCertifiedTrueCopy';
import AdminChangeOfAddress from 'src/pages/admin/AdminChangeOfAddress';
import AdminChangeOfDirectors from 'src/pages/admin/AdminChangeOfDirectors';
import AdminChangeOfSecretary from 'src/pages/admin/AdminChangeOfSecretary';
import AdminCompanyNameRegistration from 'src/pages/admin/AdminCompanyNameRegistration';
import AdminDukkaAccount from 'src/pages/admin/AdminDukkaAccount';
import AdminNameReservationsPage from 'src/pages/admin/AdminNameReservationPage';
import AdminOrder from 'src/pages/admin/AdminOrder';
import AdminOrderDetails from 'src/pages/admin/AdminOrderDetails';
import AdminStatusReport from 'src/pages/admin/AdminStatusReport';
import AdminUpdateDirectorsDetails from 'src/pages/admin/AdminUpdateDirectorsDetails';
import AdminUpdateShareholdersDetails from 'src/pages/admin/AdminUpdateShareholderDetails';
import AdminUserList from 'src/pages/admin/AdminUserList';
import AdminBusinessStarter from '../pages/admin/AdminBusinessStarter';
import AdminCacBusinessReg from '../pages/admin/AdminCacBusinessReg';
import AdminCacClientRegistration from '../pages/admin/AdminCacClientRegistration';
import AdminTin from '../pages/admin/AdminTin';
import AdminTrademark from '../pages/admin/AdminTrademark';
import PartnerDashboard from '../pages/partner/PartnerDashboard';
import RequireAuth from './auth.route';
import AuthGuard from './protected.route';
import AccountVerificationPage from 'src/pages/AccountVerificationPage';
import AdminDashboardPage from 'src/pages/admin/AdminDashboard';
import UsersList from 'src/pages/client/UsersList';
import RequireAuthAdmin from './admin.route';
import ClientCacBusinessReg from 'src/pages/client/ClientCacBusinessReg';
import ClientCompanyNameRegistration from 'src/pages/client/ClientCompanyNameRegistration';

// ----------------------------------------------------------------------

export default function Router() {
  return (
    <Routes>
      <Route
        element={
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        }
        path="/client"
      >
        <Route path="/client" element={<DashboardAppPage />} />
        <Route path="/client/business-registration/new" element={<NewBusinessRegistrationPage />} />
        <Route path="/client/name-reservation/new" element={<NewNameReservationPage />} />
        <Route path="/client/business-registration" element={<BusinessRegistrationPage />} />
        <Route path="/client/company-registration/new" element={<CompanyRegistrationPage />} />
        <Route path="/client/home" element={<DashboardAppPage />} />
        <Route path="/client/dashboard" element={<ClientDashboardPage />} />
        <Route path="/client/user" element={<UsersList />} />
        <Route path="/client/companies" element={<ClientCompanyNameRegistration />} />
        <Route path="/client/business" element={<ClientCacBusinessReg />} />
        <Route path="/client/profile" element={<ProfilePage />} />
        <Route path="/client/payment" element={<PaymentConfirmationPage />} />
      </Route>
      <Route
        element={
          <RequireAuthAdmin>
            <AdminDashboardLayout />
          </RequireAuthAdmin>
        }
        path="/admin"
      >
        <Route path="/admin" element={<AdminDashboardPage />} />
        <Route path="/admin/user" element={<UserPage />} />
        <Route path="/admin/orders" element={<AdminOrder />} />
        <Route path="/admin/list-all-users" element={<AdminUserList />} />

        <Route path="/admin/list-all-accounts" element={<AdminBankAccount />} />

        <Route path="/admin/business-registration-applications" element={<AdminBusinessNameRegistrations />} />
        <Route path="/admin/name-reservation-requests" element={<AdminNameReservationsPage />} />
        <Route path="/admin/list-of-companies" element={<AdminCompanyNameRegistration />} />
        <Route path="/admin/products" element={<ProductsPage />} />
        {/* !!! annual returns  */}
        <Route path="/admin/annual-return" element={<AdminAnnualReturn />} />
        {/* Certified True Copy */}
        <Route path="/admin/certified-true-copy" element={<AdminCertifiedTrueCopy />} />
        {/* Dukka Account */}
        <Route path="/admin/bookkeeping" element={<AdminDukkaAccount />} />
        {/* Status Report */}
        <Route path="/admin/status-report" element={<AdminStatusReport />} />
        <Route path="/admin/change-directors" element={<AdminChangeOfDirectors />} />
        <Route path="/admin/change-address" element={<AdminChangeOfAddress />} />
        <Route path="/admin/change-secretary" element={<AdminChangeOfSecretary />} />
        <Route path="/admin/update-shareholder" element={<AdminUpdateShareholdersDetails />} />
        <Route path="/admin/update-directors" element={<AdminUpdateDirectorsDetails />} />
        <Route path="/admin/trademark" element={<AdminTrademark />} />
        <Route path="/admin/tin" element={<AdminTin />} />
        <Route path="/admin/business-starter-toolkit" element={<AdminBusinessStarter />} />
        <Route path="/admin/cac" element={<AdminCacClientRegistration />} />
        <Route path="/admin/business-registration-cac" element={<AdminCacBusinessReg />} />
      </Route>

      <Route
        path="/"
        element={
          <AuthGuard>
            <HomePage />
          </AuthGuard>
        }
      />
      <Route path="/partner/:clientRef" element={<PartnerDashboard />} />
      <Route
        path="/login"
        element={
          <AuthGuard>
            <LoginPage />
          </AuthGuard>
        }
      />
      <Route
        path="/signup"
        element={
          <AuthGuard>
            <SignUpPage />
          </AuthGuard>
        }
      />
      <Route
        path="/verify-email"
        element={
          <AuthGuard>
            <VerifyEmail />
          </AuthGuard>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <AuthGuard>
            <ForgotPassword />
          </AuthGuard>
        }
      />
      <Route
        path="/reset-password"
        element={
          <AuthGuard>
            <ResetPassword />
          </AuthGuard>
        }
      />
      <Route path="/account-verification" element={<AccountVerificationPage />} />
      <Route
        path="/admin/order"
        element={
          <RequireAuth>
            <AdminOrderDetails />
          </RequireAuth>
        }
      />
      <Route element={<SimpleLayout />}>
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}
