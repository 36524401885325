import { LoadingButton } from '@mui/lab';
import { Container, Stack, Typography } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';
import LogoWhite from '../../components/logo-white/index';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  background:
    'linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #E60000 100%), url(/assets/illustrations/connor-botts.png)',
  width: '100%',
  height: 'auto',
  backgroundRepeat: 'no-repeat !important',
  backgroundSize: 'cover !important',
  //   backgroundPosition: 'center !important',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledCenteredSection = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  gap: 50,
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.common.white,
  },
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

// ----------------------------------------------------------------------

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title> Home | BeeBot </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <StyledCenteredSection>
              <LogoWhite
                disabledLink={true}
                sx={{
                  top: { xs: 16, sm: 24, md: 40 },
                  left: { xs: 16, sm: 24, md: 40 },
                }}
              />
            </StyledCenteredSection>
            <StyledCenteredSection></StyledCenteredSection>

            <Stack direction="column" spacing={2} justifyContent="center" textAlign="center">
              <StyledTypography variant="h2">All your business needs in one space </StyledTypography>
              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                loading={false}
                onClick={() => navigate('/login', { replace: true })}
              >
                Login
              </LoadingButton>
              <StyledLoadingButton
                fullWidth
                size="large"
                variant="contained"
                loading={false}
                onClick={() => navigate('/signup', { replace: true })}
              >
                Signup
              </StyledLoadingButton>
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
