import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Divider, LinearProgress } from '@mui/material';
// sections
import {
  AppWidgetSummary,
} from '../../sections/@dashboard/app';
import { useQuery } from '@apollo/client';
import { CAC_BUSINESS_REG_STATISTICS, CAC_COMPANY_REG_STATISTICS } from 'src/graphql/queries/cacClient';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const {
    data: businessRegStatistics,
    loading: businessRegStatisticsLoading,
    error: businessRegStatisticsError
  } = useQuery(CAC_BUSINESS_REG_STATISTICS);
  const {
    data: companyRegStatistics,
    loading: companyRegStatisticsLoading,
    error: companyRegStatisticsError
  } = useQuery(CAC_COMPANY_REG_STATISTICS);

  return (
    <>
      <Helmet>
        <title> Dashboard | BeeBot </title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Business Registration Statistics
            </Typography>
            {businessRegStatisticsLoading && <LinearProgress />}
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total" total={businessRegStatistics?.cacBusinessRegStatistics?.totalBusinessReg || 0}/>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Paid" total={businessRegStatistics?.cacBusinessRegStatistics?.paidBusinessReg || 0} color="info"/>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Queried" total={businessRegStatistics?.cacBusinessRegStatistics?.queriedBusinessReg || 0} color="warning" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Approved" total={businessRegStatistics?.cacBusinessRegStatistics?.approvedBusinessReg || 0} color="success"  />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Company Registration Statistics
            </Typography>
            {companyRegStatisticsLoading && <LinearProgress />}
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total" total={companyRegStatistics?.cacCompanyRegStatistics?.totalCompanyReg || 0}/>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Paid" total={companyRegStatistics?.cacCompanyRegStatistics?.paidCompanyReg? companyRegStatistics?.cacCompanyRegStatistics?.paidCompanyReg:  0} color="info"/>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Processing" total={companyRegStatistics?.cacCompanyRegStatistics?.processingCompanyReg? companyRegStatistics?.cacCompanyRegStatistics?.processingCompanyReg: 0} color="warning" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Completed" total={companyRegStatistics?.cacCompanyRegStatistics?.completedCompanyReg?  companyRegStatistics?.cacCompanyRegStatistics?.completedCompanyReg:  0} color="success"  />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
