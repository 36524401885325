import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import { SignupForm } from 'src/sections/auth/signup';
import Logo from '../components/logo';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  background:
    'linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #E60000 100%), url(/assets/illustrations/connor-botts.png)',

  height: 'auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));
const StyledCenteredSection = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> SignUp | BeeBot </title>
      </Helmet>

      <StyledRoot>
        {mdUp && <StyledSection></StyledSection>}

        <Container maxWidth="sm">
          <StyledContent>
            <StyledCenteredSection>
              <Logo
                sx={{
                  top: { xs: 16, sm: 24, md: 40 },
                  left: { xs: 16, sm: 24, md: 40 },
                }}
              />
            </StyledCenteredSection>
            <SignupForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
