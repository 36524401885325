import { gql } from '@apollo/client';

export const GET_ALL_CAC_CLIENT_PAGINATED = gql`
  query allCACClient($page: Float!, $pageSize: Float!, $searchTerm: String) {
    allCACClient(page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
      allClient {
        _id
        companyName
        active
        apiKey
        phoneNumber
        address
        email
        apiKey
        createdAt
        wallet
      }
      pageInfo {
        currentPage
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;

export const GET_ALL_CAC_REG_PAGINATED = gql`
  query GetAllBusinessRegCac($page: Float, $pageSize: Float, $searchTerm: String, $filter: String, $order: Float) {
    getAllBusinessRegCac(page: $page, pageSize: $pageSize, searchTerm: $searchTerm, filter: $filter, order: $order) {
      allClient {
        _id
        businessCommencementDate
        companyAddress
        companyCity
        companyEmail
        companyState
        companyStreetNumber
        editable
        lineOfBusiness
        meansOfId
        paymentDetails {
          _id
          amount
          serviceId
          transactionId
          status
        }
        passport
        signature
        proposedOption1
        proprietorCity
        proprietorDob
        proprietorEmail
        proprietorFirstname
        proprietorGender
        proprietorLga
        proprietorNationality
        proprietorPostcode
        proprietorPhonenumber
        proprietorServiceAddress
        proprietorState
        proprietorStreetNumber
        proprietorSurname
        transactionRef
        rcNumber
        status
        certificate
        queryReason
        cacQueryReason
        statusReport
        registrationDate
        tin
        editable
        user {
          _id
        }
        created_at
      }
      pageInfo {
        currentPage
        totalPages
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;

export const GET_ALL_CAC_CLIENT_REG_PAGINATED = gql`
  query getAllBusinessRegCacByUserClient(
    $page: Float
    $pageSize: Float
    $searchTerm: String
    $filter: String
    $order: Float
  ) {
    getAllBusinessRegCacByUserClient(
      page: $page
      pageSize: $pageSize
      searchTerm: $searchTerm
      filter: $filter
      order: $order
    ) {
      allClient {
        _id
        businessCommencementDate
        companyAddress
        companyCity
        companyEmail
        companyState
        companyStreetNumber
        editable
        lineOfBusiness
        meansOfId
        paymentDetails {
          _id
          amount
          serviceId
          transactionId
          status
        }
        passport
        signature
        proposedOption1
        proprietorCity
        proprietorDob
        proprietorEmail
        proprietorFirstname
        proprietorGender
        proprietorLga
        proprietorNationality
        proprietorPostcode
        proprietorPhonenumber
        proprietorServiceAddress
        proprietorState
        proprietorStreetNumber
        proprietorSurname
        transactionRef
        rcNumber
        status
        certificate
        queryReason
        cacQueryReason
        statusReport
        registrationDate
        tin
        editable
        user {
          _id
        }
        created_at
      }
      pageInfo {
        currentPage
        totalPages
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  query sendMail($query: String!) {
    sendQueryMail(query: $query)
  }
`;

export const TOGGLE_EDITABLE = gql`
  mutation ToggleEditable($id: String!) {
    toggleEditable(id: $id) {
      _id
      editable
    }
  }
`;

export const GET_ONE_CAC_CLIENT_USERS = gql`
  query ($id: String!, $page: Float, $pageSize: Float, $searchTerm: String) {
    getAllUsersByClientRef(id: $id, page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
      allUsers {
        firstName
        lastName
        email
        _id
        created_at
        verified
        customerRef
        clientRef
      }
      client {
        companyName
      }
      pageInfo {
        currentPage
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
      businesses
      companies
    }
  }
`;

export const GET_ONE_CAC_CLIENT_REGISTRATIONS = gql`
  query ($id: String!) {
    getAllUserRegById(id: $id) {
      allBusiness {
        _id
        proprietorFirstname
        proprietorSurname
      }
      paidRegistration
      pageInfo {
        currentPage
        pageSize
        totalPages
        totalItems
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }
  }
`;

export const APPROVE_CAC_REQUEST = gql`
  mutation sendToCac($id: String!) {
    sendToCac(id: $id) {
      _id
      businessCommencementDate
      companyAddress
      companyCity
      companyEmail
      companyState
      companyStreetNumber
      editable
      lineOfBusiness
      meansOfId
      paymentDetails {
        _id
        amount
        serviceId
        transactionId
        status
      }
      passport
      signature
      proposedOption1
      proprietorCity
      proprietorDob
      proprietorEmail
      proprietorFirstname
      proprietorGender
      proprietorLga
      proprietorNationality
      proprietorPostcode
      proprietorPhonenumber
      proprietorServiceAddress
      proprietorState
      proprietorStreetNumber
      proprietorSurname
      transactionRef
      rcNumber
      status
      certificate
      queryReason
      cacQueryReason
      statusReport
      certificate
      registrationDate
      tin
      editable
      user {
        _id
      }
      created_at
    }
  }
`;

export const RESUBMIT_QUERIED_APPLICATION = gql`
  mutation sendQueriedApplicationToCac($id: String!) {
    sendQueriedApplicationToCac(id: $id) {
      _id
      businessCommencementDate
      companyAddress
      companyCity
      companyEmail
      companyState
      companyStreetNumber
      editable
      lineOfBusiness
      meansOfId
      paymentDetails {
        _id
        amount
        serviceId
        transactionId
        status
      }
      passport
      signature
      proposedOption1
      proprietorCity
      proprietorDob
      proprietorEmail
      proprietorFirstname
      proprietorGender
      proprietorLga
      proprietorNationality
      proprietorPostcode
      proprietorPhonenumber
      proprietorServiceAddress
      proprietorState
      proprietorStreetNumber
      proprietorSurname
      transactionRef
      rcNumber
      status
      certificate
      queryReason
      cacQueryReason
      statusReport
      certificate
      registrationDate
      tin
      editable
      user {
        _id
      }
      created_at
    }
  }
`;

export const CHECK_CAC_REGISTRATION_STATUS = gql`
  query checkCacRegistrationStatus($id: String!) {
    checkCacRegistrationStatus(id: $id) {
      _id
      businessCommencementDate
      companyAddress
      companyCity
      companyEmail
      companyState
      companyStreetNumber
      editable
      lineOfBusiness
      meansOfId
      paymentDetails {
        _id
        amount
        serviceId
        transactionId
        status
      }
      passport
      signature
      proposedOption1
      proprietorCity
      proprietorDob
      proprietorEmail
      proprietorFirstname
      proprietorGender
      proprietorLga
      proprietorNationality
      proprietorPostcode
      proprietorPhonenumber
      proprietorServiceAddress
      proprietorState
      proprietorStreetNumber
      proprietorSurname
      transactionRef
      rcNumber
      status
      certificate
      queryReason
      cacQueryReason
      statusReport
      certificate
      registrationDate
      tin
      editable
      user {
        _id
      }
      created_at
    }
  }
`;

export const CAC_BUSINESS_REG_STATISTICS = gql`
  query {
    cacBusinessRegStatistics {
      totalBusinessReg
      paidBusinessReg
      notPaidBusinessReg
      submittedBusinessReg
      queriedBusinessReg
      approvedBusinessReg
      internalQueryBusinessReg
    }
  }
`;

export const CAC_COMPANY_REG_STATISTICS = gql`
  query {
    cacCompanyRegStatistics {
      totalCompanyReg
      paidCompanyReg
      notPaidCompanyReg
      processingCompanyReg
      completedCompanyReg
      pendingCompanyReg
      customerActionCompanyReg
      cancelledCompanyReg
      notApprovedCompanyReg
      internalQueryCompanyReg
    }
  }
`;
